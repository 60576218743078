@import "mmMenu";

header {
  .nav {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.nav {
  transition: height 200ms ease-out;
  position: relative;

  .active {
    color: $primary;
    background-color: $white;
  }

  &__mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__item {
    list-style: none;
    font-family: $headings-font-family;
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem 0;
    @include btn-border-drawing(transparent, $primary, 2px, bottom, right);

    &.active {
      @include btn-border-drawing($primary, $primary, 2px, bottom, right);

    }

    & + .nav__item {
      margin-left: 1rem;
    }

    &:hover {
      color: $primary;
      background-color: $white;
    }

    &_label {
      cursor: pointer;
    }

    input {
      display: none;
    }

    &--lvl2 {
      margin-left: 0;
      margin-top: 0.2rem;
    }

    &:hover .nav__lvl2 {
      display: flex;
      position: absolute;
      left: -1rem;
      background: $white;
      padding: 2.5rem 1rem 1rem;
      z-index: 100;
    }

    label {
      color: $black;
    }

    a {
      color: $black;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

      &:hover {
        color: $primary;
        background-color: $white;
      }
    }
  }

  &__lvl2 {
    display: none;
  }

  &__lvl2-list {
    padding: 0;


    .nav__item {
      margin-left: 0;
      position: relative;

      //deactivate lvl3 navigation cause missing content
      //&:hover .nav__lvl3-list {
      //  display: block;
      //  position: absolute;
      //  left: calc(100% + 1rem);
      //  background: $white;
      //  padding: 1rem;
      //  z-index: 100;
      //}
    }
  }

  &__lvl3-list {
    display: none;
    padding: 0;
    margin-left: 5rem;
    border-left: 2px solid;

    .nav__item {
      margin-left: 0;
    }
  }
}