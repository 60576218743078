.contact-form{
  position: relative;
  background-color: $primary;
  padding-bottom: 5rem;

  .wpcf7-form.invalid {
    color: $white;

    .wpcf7-response-output {
      border-color: $white;
    }
  }

  .text-white {
    a {
      color: $white;
    }
  }
}


.form-group {
  margin-bottom: 1rem;
}

.form {
  position: relative;

  &__headline {
    color: $white;
  }



  &__image-descr {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #112A5A;
    border: 1px solid #fbfbfb;
    padding: 0.5rem;
    color: $white;
    font-size: $font-size-sm;

    @include media-breakpoint-up(md) {
      bottom: -3rem;
      right: -1rem;
    }
    a {
      color: inherit;
    }
  }

  &__container {

    margin: -3rem 0 2rem;

    &--orange {
      background-color: $secondary;
      color: $white;
      padding: 2rem;
      margin-bottom: -3rem;
    }
  }

  &__copy {
    margin-bottom: 2rem;
  }

  input:not(input[type=submit]), textarea {
    color: $primary;
    width: 100%;
    background: $white;
    border: none;
    height: 2rem;
    padding: 0.5rem;

  }

  textarea {
    height: 6rem;
  }

  input[type=submit] {
    min-width: 200px;
    text-align: center;
  }

  label {
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    width: 100%;
  }

}