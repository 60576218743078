.key-visual {
  img {
    max-height: 60vh;
    object-fit: cover;
    object-position: center;
  }
  &__image {
    position: relative;
  }

  &__headline {
    text-align: start;
  }

  &__list {
    list-style: none;
  }

  &__list-item {
    --font-size: 1.2rem;

    font-size: var(--font-size);
    margin-bottom: 0.5rem;

    &:before {
      content: '';
      background-image: url("#{$icon-path}check-mark.svg");
      background-size: contain;
      display: inline-block;
      width: 2em;
      height: 1.5em;
      position: relative;
      top: 0.4rem;
      margin-right: 0.2rem;
      background-repeat: no-repeat;
    }

    .icon {
      width: var(--font-size);
      margin-right: 0.5rem;
    }
  }

  &__wave {
    width: 100%;
    position: absolute;
    bottom: -15px; //ensure there is no ugly border from the image
    left: 0;
    @include media-breakpoint-up(md) {
      bottom: -30px;
    }
  }

  &__container {
    background: $primary;
    color: $white;
    padding-bottom: 5rem;

  }

  &__btn_wrapper {
    text-align: right;
    margin-top: 3rem;
  }

  &__copytext {
    max-width: 80ch;
  }


}