.teaser {

  &-card {

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__headline {
      font-size: 1.4rem;
      color: $white;
      border: 2px solid white;
      padding: 0.5rem;
      text-align: start;
      display: inline-block;
    }
  }

}
