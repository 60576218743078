.wp-block-image{
  img {
    width: 100%;
    height: auto;
  }
}

img {
  width: 100%;
  height: auto;
}

.img {
  &__schnittstelle {
    border: 2px solid $primary;
    padding: 0.5rem;
    background: white;
    margin: 1rem 0;
    transition: transform 300ms ease-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}