h1, h2, h3, h4, h5, h6 {
  text-align: center;
  margin: 1.5rem 0 0;
}

.heading {

  &--primary {
    color: $primary;
  }

  &--negative {
    color: $white;
  }
}