.wave {
  width: 100%;
  margin-bottom: -1px; //ensure that no visual gap existis on resizing

  &--blue {
    color: $primary;
  }
  &--bottom {
    transform: rotate(180deg);
  }
}