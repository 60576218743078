$white:           #fbfbfb;
$black:           #000;
$black-dirty:     #262524;

$primary:         #112A5A;
$secondary:       #F1002D;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-900 !default;

$modal-header-border-width:         0;
$modal-footer-border-width:         0;
$modal-sm:                          100%;
$modal-md:                          80%;
$modal-lg:                          80%;
$modal-xl:                          80%;



//$enable-caret:                true !default;
$enable-rounded:                false;
//$enable-shadows:              false !default;
//$enable-gradients:            false !default;
//$enable-transitions:          true !default;
//$enable-reduced-motion:       true !default;
//$enable-smooth-scroll:        true !default;
//$enable-grid-classes:         true !default;
//$enable-button-pointers:      true !default;
//$enable-rfs:                  true !default;
//$enable-validation-icons:     true !default;
//$enable-negative-margins:     false !default;
//$enable-deprecation-messages: true !default;
//$enable-important-utilities:  true !default;

$body-bg:                     $white !default;
$body-color:                  $primary;
//$body-text-align:           null !default

$link-color:                currentColor;
$link-decoration:             underline;
$link-shade-percentage:     20% !default;
$link-hover-color:          darken($white, 20);
//$link-hover-decoration:     null !default;

$font-family-sans-serif: 'Noto Sans', 'Arial', sans-serif;
$font-size-base:          1.2rem;
$headings-font-family:   'Noto Sans', 'Arial', sans-serif;

/*
* button styles
*/

//$btn-padding-y:               $input-btn-padding-y !default;
//$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:               $headings-font-family;
//$btn-font-size:               ;
//$btn-line-height:             $input-btn-line-height !default;
$btn-hover-bg-shade-amount:       0;
$btn-hover-bg-tint-amount:        0;

$icon-path: '/content/themes/woetzer-2022/assets/dist/images/';


$nav-tabs-border-color:             none;
//$nav-tabs-border-width:             $border-width;
//$nav-tabs-border-radius:            $border-radius;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color:        none;
//$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-active-border-color: none;

/*
* input styles
*/
//
//$input-btn-font-family:         $headings-font-family;
//$input-color:                   $white;
//$input-bg:                      transparent;
$input-border-color:            $primary;
$input-border-width:            2px;
//$form-select-indicator-color:   $primary;
//$input-focus-border-color:      $white;
//$input-focus-box-shadow:        none;
//
//$input-select-option-bg:        $black;
//$input-select-option-color:     $white;



//$form-select-indicator:         url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'><path fill=\'none\' stroke=\'#{$form-select-indicator-color}\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M2 5l6 6 6-6\'/></svg>') !default;
//$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image