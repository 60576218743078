@use "sass:math";
@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  //color: $color;
  transition: color $duration math.div($duration, 3);
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }

    &::before {
      transition-delay: 0s, 0s, $duration;
    }

    &::after {
      transition-delay: 0s, $duration, 0s;
    }
  }
}


.btn {

  &-primary {
    color: $white;
    @include btn-border-drawing($primary, $white);

    &.active { //used for nav-tabs active button state
      color: $primary;
    }
  }

  &-secondary {
    color: $white;
    position: relative;
    @include btn-border-drawing($secondary, $white);

    //&:before {
    //  content: "";
    //  background-color: transparent;
    //  width: calc(100% - 2px);
    //  height: calc(100% - 2px);
    //  position: absolute;
    //  top: 1px;
    //  left: 1px;
    //  border: 1px solid $white;
    //}


  }

}

