.headline {
  padding: 2rem 0 0;
  color: $white;
  text-align: center;
  background: $primary;

  &--white {
    background: transparent;
    color: $primary;
  }
}