header {
  background-color: $white;
  z-index: 1050;
  position: sticky;
  top: 0;
}

.header {
  &__before {
    background-color: $primary;
    color: $white;
    text-align: end;
    padding: 0.5rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav {
    //@include media-breakpoint-up(lg) {
    //  margin-right: 10vw;
    //}
  }

  &__logo {
    flex: 1 0 0;
    background: white;
    max-width: 30vw;
    margin-top: -2rem;
    margin-bottom: -1rem;
    border: 1px solid white;
    @include media-breakpoint-up(lg) {
      max-width: 20vw;
    }
  }
}