.staff {

  &__container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__content {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__cite {
    position: absolute;
    color: $white;
    background: $primary;
    font-size: 1rem;
    width: 0;
    z-index: 1;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  &__phone {
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__image-descr {
    background: $primary;
    padding: 0.5rem;
    font-size: 1rem;

    &:hover {
      .staff__cite {
        animation: growLeft 300ms forwards;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  @keyframes growLeft {
    0% {
      width: 0;
      font-size: 0;
    }

    80% {
      font-size: 0.1rem
    }

    99% {
      width: 150%;
    }
    100% {width: 150%; font-size: 0.8rem; padding: 0.5rem}

  }
}