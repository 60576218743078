.mm-menu {
  --mm-color-text: $black;
  --mm-color-text-dimmed: $black;
  --mm-color-background: $white;
  --mm-color-background-highlight: $secondary;
  --mm-color-background-emphasis: $primary;
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-family: $headings-font-family;
}
.mm-navbar {
  color: $white;
  background-color: $primary;
}


/*************************************
Burger Menu Styles
 */
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}


.line {
  fill: none;
  stroke: $primary;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}