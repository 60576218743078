.footer {
  font-size: 1rem;
  overflow: hidden;
  background-color: $primary;
  color: $white;
  border-top: 2px solid $white;
  @include media-breakpoint-up(md) {
    padding: 2rem;
  }

  a {
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  &__headline {
    font-size: $font-size-base;
    text-align: start;
    margin: 0;
  }

  &__image {
    border: 1px solid $white;
    max-width: 200px;
    margin: 1rem 0;
  }
}
