@font-face {
  font-family: 'Noto Sans';
  src: url('../../../fonts/NotoSans-Bold.woff2') format('woff2');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../../../fonts/NotoSansDisplay-Medium.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
}