.three_cols_blue {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
}

.col_img_text {
  background-color: #112A5A;
  float: left;
  position: relative;
  width: 29%;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  margin: 5px;
  color: #fbfbfb;

  &__headline {
    text-align: center;
  }

  &__image {
    position: relative;
  }

  &__list {
    list-style: none;
    text-align: left;
  }

  &__list-item {
    margin-left: -5%;
    margin-right: auto;
    font-size: 18px;
    margin-bottom: 0.5rem;
    font-weight: lighter;


    &:before {
      content: '';
      background-image: url("#{$icon-path}check-mark.svg");
      background-size: contain;
      display: inline-block;
      width: 2em;
      height: 1.5em;
      position: relative;
      top: 0.4rem;
      margin-right: 0.2rem;
      background-repeat: no-repeat;
    }

    .icon {
      width: var(--font-size);
      margin-right: 0.5rem;
    }
  }

  &__col-button-wrapper {
    text-align: right;
    margin-top: 2rem;
  }

  &__text {
    text-align: left;
    font-weight: lighter;
    margin-left: 1rem;
  }

  &__title {
    margin-top: 4%;
    border: 1px solid #fbfbfb;
    padding: 2px;
  }


}

.col-img-text-images {
  width: 100%;
  height: 250px;
}

.full_width_button {
  background-color: yellow;
}

